/*
 * Swiper 2.7.6
 * Mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/sliders/swiper/
 *
 * Copyright 2010-2015, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under GPL & MIT
 *
 * Released on: February 11, 2015
*/
/* ===============================================================
Basic Swiper Styles 
================================================================*/
.swiper-container {
	margin:0 auto;
	position:relative;
	overflow:hidden;
	direction:ltr;
	-webkit-backface-visibility:hidden;
	-moz-backface-visibility:hidden;
	-ms-backface-visibility:hidden;
	-o-backface-visibility:hidden;
	backface-visibility:hidden;
	/* Fix of Webkit flickering */
	z-index:1;
	height: auto!important;
}
.swiper-wrapper {
	position:relative;
	width:100%;
	-webkit-transition-property:-webkit-transform, left, top;
	-webkit-transition-duration:0s;
	-webkit-transform:translate3d(0px,0,0);
	-webkit-transition-timing-function:ease;
	
	-moz-transition-property:-moz-transform, left, top;
	-moz-transition-duration:0s;
	-moz-transform:translate3d(0px,0,0);
	-moz-transition-timing-function:ease;
	
	-o-transition-property:-o-transform, left, top;
	-o-transition-duration:0s;
	-o-transform:translate3d(0px,0,0);
	-o-transition-timing-function:ease;
	-o-transform:translate(0px,0px);
	
	-ms-transition-property:-ms-transform, left, top;
	-ms-transition-duration:0s;
	-ms-transform:translate3d(0px,0,0);
	-ms-transition-timing-function:ease;
	
	transition-property:transform, left, top;
	transition-duration:0s;
	transform:translate3d(0px,0,0);
	transition-timing-function:ease;

	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;

	cursor:url(../img/drag.png) 16 9, ew-resize!important;
	margin: 0 auto;
	height: inherit!important;
	float: left;
}
.pagination-hidden .swiper-wrapper{
	-webkit-transform:translate3d(0px,0,0)!important;
	-moz-transform:translate3d(0px,0,0)!important;
	-o-transform:translate3d(0px,0,0)!important;
	-o-transform:translate(0px,0px)!important;
	-ms-transform:translate3d(0px,0,0)!important;
	transform:translate3d(0px,0,0)!important;
	left: 0!important;
	cursor: auto!important;
}
.swiper-free-mode > .swiper-wrapper {
	-webkit-transition-timing-function: ease-out;
	-moz-transition-timing-function: ease-out;
	-ms-transition-timing-function: ease-out;
	-o-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
	margin: 0 auto;
}
.swiper-slide {
	float: left;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;

	height: inherit!important;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
	-ms-touch-action: pan-y;
}
.swiper-wp8-vertical {
	-ms-touch-action: pan-x;
}

/* ===============================================================
Your custom styles, here you need to specify container's and slide's
sizes, pagination, etc.
================================================================*/
.swiper-container {
	/* Specify Swiper's Size: */

	/*width:200px;
	height: 100px;*/
}
.swiper-slide {
	/* Specify Slides's Size: */
	
	/*width: 100%;
	height: 100%;*/
}
.swiper-slide-active {
	/* Specific active slide styling: */
	
}
.swiper-slide-visible {
	/* Specific visible slide styling: */	

}
/* ===============================================================
Pagination Styles
================================================================*/
.swiper-container .pagination{
	text-align: center;
	width: 100%;
	margin: 35px 0 0 0;
}
.swiper-container.pagination-hidden .pagination{
	display: none!important;
}
.swiper-pagination-switch {
	/* Stylize pagination button: */	
	width: 10px;
	height: 10px;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	display: inline-block;
	position: relative;
	margin: 0 5px;
	cursor: pointer;
	border: 2px #e3e3e3 solid;
}

.swiper-active-switch {
	/* Specific active button style: */	
	background: #03b4ea;
	border-color: #03b4ea;
}

.swiper-visible-switch {
	/* Specific visible button style: */	

}

.swiper-pagination-switch{
	-moz-transition:all 300ms ease-out;
	-o-transition:all 300ms ease-out;
	-webkit-transition:all 300ms ease-out;
	transition:all 300ms ease-out;
	-ms-transition:all 300ms ease-out;
}

#content-block .swiper-arrow-left, #content-block .gallery-arrow-left{
	position: absolute;
	top: 50%; 
	margin-top: -30px;
	left: 0;
	z-index: 1;
}

#content-block .swiper-arrow-right, #content-block .gallery-arrow-right{
	position: absolute;
	top: 50%; 
	margin-top: -30px;
	right: 0;
	z-index: 1;
}