#logo {
    max-width: 6rem;
}
body.style-9 .swiper-tabs .block-title::before,
body.style-9 .search-button, .header-wrapper.style-9 .search-button
{
    background: #ea5923;
}
body.style-9 .price .current, .column-article-entry > .read-more {
    color: #c7192c;
}
.search-button:hover {
    background: #c7192c !important;
}
.product-image-label.type-1 {
    right: 7px;
    left: inherit;
}
.btn-floating {
    background-color: #4dc247!important;
}
/** custom search  **/
div.search-field {
    margin-right: 0;
}
.search-field input[type="text"] {
    width: 95%;
}
/** end custom search **/
.footer-address a b, .mozaic-banner-content .title, .price, .sale-entry .sale-price span, a {
    color: #ea5923;
}
body.style-9 .swiper-active-switch, body.style-9 .bubbles span {
    background: #c7192c;
    border-color: #c7192c;
}
.product-detail-box .rating-box .star, .rating-box,
body.style-9 .list-type-1 li .fa,
.footer-wrapper.style-9 footer.type-2 .copyright a,
body.style-9 .list-type-1 a:hover
{
    color: #ea5923;
}

a.title {
    height: 3.5rem;
}

body.style-9 .product-slide-entry .title:hover
{
    color: #c7192c;
}
#top-mobile div.menu-button {
    right: inherit;
}

#top-mobile a.logo {
    max-width: 12rem;
    display: inline-block;
    margin: 0.9rem 0 0 4rem;
}

#top-mobile a.logo img {
    display: block;
    width: 100%;
}

#top-mobile a.header-functionality-entry {
    float: right;
    display: inline-block;
    margin-top: 0.5rem;
}
div.swiper-slide.mobile .custom-col-1 {
    padding: 0;
}

@media (max-width: 1199px) {
    header .middle-entry {
        display: table-cell;
        width: 65%;
    }

    .header-middle .right-entries {
        width: 300px;
    }
}

@media (max-width: 991px) {
    header .middle-entry {
        display: table-cell;
        width: 50%;
    }
}
